
import { useEffect } from 'react'
import LOGO from "../assets/logo.png"
import Footer from './components/Footer'
import { isMobile } from 'react-device-detect'

function CGU() {

    useEffect(() => {
        document.body.style.backgroundColor = "black"
    }, []);

    return (
        <>
            <div className="app">
                <div className="part_two" style={{ width: isMobile? "calc(100vw - 40px)" : "900px" }}>
                    <div className="coming">
                        Dernière révision: 11 Novembre 2024
                    </div>
                    <img src={LOGO} alt="Dropora logo" className="logo" />
                    <div className="tags">
                        CGU
                    </div>
                    <div className="text">
                        1. OBJET
                    </div>
                    <div className="mintext">
                        Les présentes Conditions Générales d’Utilisation (les <strong>« CGU »</strong>) régissent les droits,
                        obligations et restrictions liés à l’utilisation de l’Application mobile Dropora (ci-après l’<strong>« Application »</strong>)
                        par l’Utilisateur (<strong>« l’Utilisateur »</strong>).
                    </div>
                    <div className="mintext">
                        Lors de son inscription, chaque Utilisateur accepte expressément les présentes CGU en cochant la case d’acceptation des CGU, et s’engage à les respecter sans réserve.
                    </div>
                    <div className="mintext">
                        SI VOUS N’ETES PAS D’ACCORD AVEC LES CONDITIONS D’UTILISATION MENTIONNEES CI-APRES, MERCI DE NE PAS UTILISER L’Application.
                    </div>
                    <div className="mintext">
                        L’utilisation de l’application mobile “Dropora” et du site Internet Dropora.com vaut acceptation sans réserves des présentes Conditions Générales d’Utilisation.
                    </div>
                    <div className="mintext">
                        Ces CGU sont susceptibles d’être unilatéralement modifiées ou complétées à tout moment, les Utilisateurs de l’Application sont invités à les consulter de manière régulière sur le Site <a href={"https://Dropora.com"}>https://Dropora.com</a> ou sur l’Application.
                    </div>
                    <div className="text">
                        2. MENTIONS LEGALES
                    </div>
                    <div className="mintext">
                        Le Site et l’Application sont édités par Dropora SAS.
                        <br />
                        Responsable de l'Edition : Monsieur Gonzague Grandval
                    </div>
                    <div className="mintext">
                        Le Site et l’Application Dropora.com est hébergé par la société :
                        <br />
                        Amazon Web Services LLC
                        <br />
                        P.O. Box 81226
                        <br />
                        Seattle, WA 98108-1226
                    </div>
                    <div className="text">
                        3. DÉFINITIONS
                    </div>
                    <div className="mintext">
                        <strong>Token / Jeton, Communauté, Technologie, Partenaire, Gagnant / lauréat, avatar, Marque</strong>
                    </div>
                    <div className="mintext">
                        <strong>Site </strong> : désigne l’URL <a href={"https://www.Dropora.com"}>https://www.Dropora.com</a> sur laquelle sont disponibles les liens de téléchargements de l’Application ainsi que les présentes Conditions Générales d’Utilisation.
                    </div>
                    <div className="mintext">
                        <strong>Application</strong> : désigne l’Application mobile Dropora, disponible sur l’Apple Store et l’Android Store. L’Application intègre un wallet généré à l’inscription et permettant de gérer le Compte de l’Utilisateur, ses Coins, ses Cartes et ses Drops.
                    </div>
                    <div className="mintext">
                        <strong>Drop</strong> : désigne la représentation numérique d’un produit ou service détenu par un Utilisateur. Un Drop possède une durée de validité comprise entre deux dates explicites.
                    </div>
                    <div className="mintext">
                        <strong>Utilisateur</strong> : désigne toute personne se connectant et participant à des collections sur l’Application Dropora.
                    </div>
                    <div className="mintext">
                        <strong>Compte Utilisateur</strong> : désigne les données transmises par l'Utilisateur dans les différentes rubriques de l’Application.
                    </div>
                    <div className="mintext">
                        <strong>Identifiant</strong> : désigne les informations nécessaires à l'identification d'un Utilisateur sur l’Application pour accéder à son compte Utilisateur.
                    </div>
                    <div className="mintext">
                        <strong>Mot de passe</strong> : Le « Mot de passe » est une information confidentielle, que l'Utilisateur doit garder secret, lui permettant de se connecter à son compte sur l’Application avec son identifiant.
                    </div>
                    <div className="mintext">
                        <strong>Blockchain</strong> : désigne une base de données distribuée dont les informations envoyées par les Utilisateurs et les liens internes à la base sont vérifiés et groupés à intervalles de temps réguliers en blocs, formant ainsi une chaîne.
                    </div>
                    <div className="mintext">
                        <strong>NFT</strong> : (Jeton non Fongible) désigne un fichier numérique auquel un certificat numérique a été attaché au moyen d'un enregistrement sur une Blockchain. En l’espèce, les NFT Dropora sont disponibles sur la Blockchain Polygon.
                    </div>
                    <div className="mintext">
                        <strong>Wallet</strong> : désigne un portefeuille numérique qui permet de transférer et de stocker des crypto-monnaies ou des crypto-actifs, dont les NFT.
                    </div>
                    <div className="mintext">
                        <strong>Oeuvre numérique</strong> : désigne le visuel proposé gratuitement sur l’Application ou échangé sous forme de NFT sur le marché secondaire.
                    </div>
                    <div className="mintext">
                        <strong>Données Personnelles</strong> : désigne toute information se rapportant à une personne physique identifiée ou identifiable, telles que, notamment, le nom, le prénom, l’adresse électronique, le numéro de téléphone, l’adresse postale, des coordonnées bancaires... (conformément au Règlement (UE) 2016/679 du Parlement européen et du Conseil du 27 avril 2016, relatif à la protection des personnes physiques à l'égard du traitement des données à caractère personnel et à la libre circulation de ces données). Lest détails en sont donnés dans la politique de confidentialité et protection des données personnelles de Dropora.
                    </div>
                    <div className="mintext">
                        <strong>Editeur</strong> : désigne l’administrateur et responsable de l’Application Dropora et du Site Dropora.com.
                    </div>
                    <div className="mintext">
                        <strong>Carte</strong> : désigne les images rares de chaque Collection. Les Cartes sont émises en quantité limitée, chaque Carte de la collection ayant elle-même une quantité différente en fonction de son niveau de rareté. Chaque Carte est un NFT, enregistré comme tel dans l’Application, et distribué aléatoirement aux Utilisateurs.
                    </div>
                    <div className="mintext">
                        <strong>Pack</strong> : désigne un lot de Cartes à gagner en échange d’un nombre de Coins.
                    </div>
                    <div className="mintext">
                        <strong>Collection</strong> : désigne une série de Cartes émises en quantité limitée par la société Dropora, pour son compte ou pour le compte de sociétés tierces. Les collections sont caractérisées par un Drop (en 1 ou plusieurs exemplaires) à gagner à l’issue d’une période collection de durée variable.
                    </div>
                    <div className="mintext">
                        <strong>Coins</strong> : désigne une monnaie virtuelle interne à l’Application Dropora, gagnée en fonction de l’activité de l’Utilisateur sur l’Application, et permettant de réaliser des tirages de Packs.
                    </div>
                    <div className="mintext">
                        <strong>XP (Experience)</strong> : désigne un système de points gagnés par les Utilisateurs lors de leur progression et implication dans l’Application. Les points d’XP permettent d’atteindre des niveaux dans l’Application auxquels correspondent des bonus de type, Carte gratuite, coins gratuits, etc.
                    </div>
                    <div className="mintext">
                        <strong>Trophée</strong> : désigne un palier d’Expérience à partir duquel les récompenses peuvent évoluer.
                    </div>
                    <div className="mintext">
                        <strong>Nom d’Utilisateur (Username)</strong> : désigne le pseudonyme choisi par l’Utilisateur pour le désigner dans l’Application. L’Application vérifie que le Nom d’Utilisateur choisi n’est pas déjà utilisé par un autre Utilisateur et lui propose de changer de pseudonyme le cas échéant.
                    </div>
                    <div className="mintext">
                        <strong>Place de Marché (Marketplace)</strong> : désigne la section de l’Application où les Utilisateurs peuvent mettre en vente les Cartes qu’ils ne souhaitent pas conserver, et également acheter des Cartes à d’autres Utilisateurs.<br />
                        L’Utilisateur a la possibilité d’acheter ou de vendre immédiatement une Carte si une offre de vente ou d’achat respectives ont déjà été publiée.
                        <br />
                        L’Utilisateur peut également formuler une offre sur la Marketplace en indiquant le prix qu’il serait prêt payer ou se faire payer pour une Carte donnée.
                        <br />
                        La Marketplace affichera la meilleure offre à l’ensemble des Utilisateurs.
                    </div>
                    <div className="mintext">
                        <strong>Notification</strong> : désigne une action où l’Utilisateur est informé du contenu d’un acte dans l’application mobile.
                    </div>
                    <div className="text">
                        4. PRÉSENTATION DU SERVICE
                    </div>
                    <div className="mintext">
                        Dropora est une Application offrant à ses Utilisateurs de participer à des tirages de Cartes numériques aléatoires leur permettant de réaliser des Collections. A l’issue de la période impartie propre à chaque collection, l’Utilisateur peut gagner des Drops dont les sous-jacents sont des cadeaux physiques, dématérialisés ou la possibilité de contribuer à la mission d’une association.
                    </div>
                    <div className="mintext">
                        Par leurs actions dans l’Applications, les Utilisateurs obtiennent des pièces numériques ainsi que des points leur permettant de faire progresser leur niveau d’expérience dans le jeu et d’obtenir le plus de cartes possibles leur permettant ainsi de tenter de compléter les collections de cartes.
                    </div>
                    <div className="mintext">
                        Les pièces numériques permettent de sélectionner des packs de Cartes à gagner.
                    </div>
                    <div className="mintext">
                        Dropora fournit les informations sur chaque Carte et Collection, sur leur rareté et la durée de chaque campagne. Les informations de chaque Carte ou collection n’évoluent pas pendant la durée de la Collection. Toutefois, l’Editeur ne pourra être tenu responsable des omissions, des inexactitudes et des carences dans la mise à jour, qu’elles soient de son fait ou du fait des tiers partenaires qui lui fournissent ces informations.
                    </div>
                    <div className="mintext">
                        L’utilisation du service Dropora suppose la création d’un compte utilisateur (ci-après le “Compte Utilisateur”) et la fourniture de données à caractère personnel telles que votre numéro de téléphone mobile et votre date de naissance. A défaut, l’ouverture du Compte Utilisateur ne sera pas possible. L’utilisation des données à caractère personnel des Utilisateurs est régie par la documentation dédiée « RGPD » mise à disposition dans l’application et le site internet.
                    </div>
                    <div className="mintext">
                        Pour bénéficier de l’Application, l’Utilisateur doit disposer d’un Smartphone et/ou d’une tablette avec un accès internet, ainsi que d’un compte personnel.
                    </div>
                    <div className="text">
                        5. MESSAGES DE DROPORA
                    </div>
                    <div className="mintext">
                        Dans le cadre du service rendu par l’Application, vous pourrez recevoir des notifications (messages “push”), des alertes, des courriels, ou d’autres types de messages, que ce soit dans l’Application ou en dehors de l’Application.
                    </div>
                    <div className="mintext">
                        Certains messages peuvent être liés à votre profil, à vos actions, à vos offres, à vos achats, à votre lieu géographique, à vos préférences. Certains de ces messages peuvent vous être facturés par votre opérateur de téléphonie mobile, en fonction de votre abonnement. Vous êtes seul responsable des éventuels frais de communication engendrés par les messages envoyés par Dropora.
                    </div>
                    <div className="text">
                        6. FONCTIONNEMENT DU SERVICE DROPORA
                    </div>
                    <div className="mintext">
                        <strong>6.1 Création de compte</strong>
                    </div>
                    <div className="mintext">
                        Chaque nouvel Utilisateur peut créer un compte sur l’Application après avoir accepté les CGU et en renseignant ses données.
                    </div>
                    <div className="mintext">
                        La création d’un compte Utilisateur débute par la saisie de son numéro de téléphone, validé par un SMS de confirmation.
                    </div>
                    <div className="mintext">
                        A ce stade, une clé privée cryptographique compatible EVM (Electrum Virtual Machine) sera créée dans l’enclave sécurisée du téléphone de l’Utilisateur, dont une clé publique sera dérivée et partagée avec Dropora.
                        <br />
                        Ainsi, la connexion à l’Application par l’Utilisateur se fera automatiquement par la signature d’un message dans le téléphone, et partagé et reconnu par le serveur d’authentification de Dropora pour ouvrir l’accès personnalisé à l’Application.
                    </div>
                    <div className="mintext">
                        L’Utilisateur renseigne alors son âge et son genre, puis est amené à choisir un Nom d’Utilisateur unique. Dropora refusera tout Nom d’Utilisateur faisant l'apologie de la violence ou de la haine envers certains individus ou groupes d’individus.
                    </div>
                    <div className="mintext">
                        Si vous constatez un Nom d’Utilisateur qui enfreint cette règle, nous vous remercions de bien vouloir nous le signaler en nous écrivant à <a href="mailTo:contact@dropora.com">contact@dropora.com</a>.
                    </div>
                    <div className="mintext">
                        Une fois le Nom d’Utilisateur validé, l’Utilisateur est invité à récupérer un premier lot de Coins offerts pour démarrer son Utilisation de l’Application.
                    </div>
                    <div className="mintext">
                        Dans le menu de l’Application, l’Utilisateur a la possibilité de renseigner son nom et prénom afin que d’autres Utilisateurs puissent facilement le retrouver avec la fonction de recherche. Cette possibilité demeure facultative et l’Utilisateur peut également rendre ses informations privées afin qu’elles ne soient pas visibles des autres Utilisateurs.
                    </div>
                    <div className="mintext">
                        Sur le même principe, l’Utilisateur peut télécharger et afficher dans l’Application une photo de profil personnalisée ou avatar, qui sera publique ou privée selon de choix présenté ci-dessus.
                    </div>
                    <div className="mintext">
                        L’Utilisateur s’engage à choisir une photo ou une image libre de droits. Dropora se réserve le droit de bloquer, supprimer ou bannir un Utilisateur ne respectant pas cette règle.
                    </div>
                    <div className="mintext">
                        Dans le menu de l’Application, la rubrique « Account » ou « compte » permet à l’Utilisateur de renseigner des informations personnelles telles que son nom et prénom, adresse Email et adresse postale afin que les Drops puissent lui être adressé en cas de gain.
                    </div>
                    <div className="mintext">
                        Malgré les vérifications, Dropora ne pourra être tenu responsable de la mauvaise saisie des informations entrainant une erreur dans l’envoi électronique ou postal du Drop.
                    </div>
                    <div className="mintext">
                        L’Utilisateur reste seul responsable de l’utilisation qu’il en fait, et toute connexion à son compte sera présumée être faite par lui.
                        <br />
                        En cas de perte ou de vol d’un mot de passe, l’Utilisateur est invité à réinitialiser immédiatement son mot de passe en cliquant sur « mot de passe oublié ? » dans l’espace « connexion ».
                    </div>
                    <div className="mintext">
                        <strong>6.2 Responsabilité de l’Utilisateur</strong>
                    </div>
                    <div className="mintext">
                        En utilisant l’Application Dropora, l’Utilisateur s’interdit notamment de :
                    </div>
                    <div className="mintext">
                        - effectuer tout acte qu’il ne serait pas autorisé à réaliser en Application des lois et de la réglementation applicable ;
                    </div>
                    <div className="mintext">
                        - reproduire et/ou diffuser tout contenu qu’il n’est pas autorisé à transmettre en Application des lois et de la réglementation applicable ;
                    </div>
                    <div className="mintext">
                        - essayer de porter atteinte à tout autre Utilisateur, à l’hébergeur ou réseau, ce qui comprend notamment le fait d’exposer le Site ou l’Application à un virus, de créer une saturation, d’inonder le serveur, etc. ;
                    </div>
                    <div className="mintext">
                        - accéder aux données qui ne lui sont pas destinées ou entrer dans un serveur / un compte auquel l’Utilisateur n’est pas autorisé à avoir accès ;
                    </div>
                    <div className="mintext">
                        - tenter de sonder, de scruter ou de tester la vulnérabilité du Site ou d'un réseau, ou encore d’enfreindre les mesures de sécurité ou d'authentification sans en avoir reçu l’autorisation ;
                    </div>
                    <div className="mintext">
                        - usurper l’identité d’une autre personne et notamment d’un autre Utilisateur ;
                    </div>
                    <div className="mintext">
                        L’Utilisateur est responsable de la conservation de sa clé cryptographique, contenue dans l’enclave sécurisée de son téléphone. Il peut choisir de la transférer à un autre téléphone, sous réserve de conserver le même numéro de téléphone.
                        <br />
                        Si l’Utilisateur change de numero de téléphone, il est invité à le modifier dans le menu « account » de l’Application.
                        <br />
                        Si l’Utilisateur change de téléphone sans avoir pu récupérer sa clé cryptographique, il est invité à entrer en contact avec Dropora pour analyser les possibilité de récupération de son historique dans l’Application.
                    </div>
                    <div className="mintext">
                        Dropora est uniquement accessible aux personnes physiques âgées de plus de 18 ans.
                    </div>
                    <div className="mintext">
                        Uniquement les mineurs de 13 à 17 ans sont tolérés à participer à ce jeu, à la condition qu'ils aient préalablement obtenu de leurs parents ou de la personne exerçant l'autorité parentale l'autorisation de le faire. Le fait pour eux de participer implique qu'ils ont obtenu cette autorisation. Dropora ne peut être tenu responsable du manquement à cette règle et se réserve le droit d'en demander la justification écrite, à tout moment et de procéder à toutes les vérifications nécessaires. L’Utilisateur mineur admet que l’utilisation de l’Application est un « acte courant » au sens de l’article 1148 du Code civil.
                    </div>
                    <div className="mintext">
                        Ne peuvent bénéficier des Drops ou lots gratuits offerts par Dropora, les collaborateurs de Dropora, de même que les personnes ayant collaboré, à un titre quelconque, à l'organisation des jeux proposés par Dropora, sauf afin de veiller au bon fonctionnement de celui-ci.
                    </div>
                    <div className="mintext">
                        L’Utilisateur s’engage à ne pas :
                    </div>
                    <div className="mintext">
                        Créer plusieurs comptes sur l’Application ;
                    </div>
                    <div className="mintext">
                        Créer de compte pour une autre personne que lui-même sans l’autorisation de cette dernière ;
                    </div>
                    <div className="mintext">
                        Utiliser comme nom d’utilisateur le nom d’une autre personne avec l’intention d’usurper son identité ;
                    </div>
                    <div className="mintext">
                        Utiliser un pseudonyme à la place de ses noms et prénoms dans le formulaire lorsque ceux-ci sont demandés ou d'indiquer des coordonnées inexactes ;
                    </div>
                    <div className="mintext">
                        Utiliser un nom d’utilisateur grossier, vulgaire, obscène ou illégal d’une autre manière sous peine de s'exposer les cas échéants, aux dispositions de l'article 8 du présent règlement.
                    </div>
                    <div className="mintext">
                        L’adhésion à l’Application est gratuite et n’engendre aucune obligation d’achat (notamment sur les récompenses ou lieux référencés) ni aucune contrepartie financière de la part de l’Utilisateur envers Dropora SAS.
                    </div>
                    <div className="mintext">
                        En vue de bénéficier de la totalité des Services de l’Application, l’Utilisateur devra accepter de partager sa géolocalisation sans quoi l’Utilisateur ne pourra se voir attribuer certains avantage et/ou Récompense de l’Application. Pour plus d’informations concernant le traitement des données de géolocalisation, l’Utilisateur peut consulter la Politique de confidentialité de Dropora SAS.
                    </div>
                    <div className="mintext">
                        L’Utilisateur déclare et garantit que les informations fournies sont exactes et complètes.
                    </div>
                    <div className="mintext">
                        L’Utilisateur s’engage à ne pas transférer volontairement des fausses données à la Société.
                    </div>
                    <div className="mintext">
                        Plus généralement, Dropora, se réserve le droit d'annuler, ou de suspendre toute inscription :
                    </div>
                    <div className="mintext">
                        ne se conformant pas aux présentes Conditions Générales d'Utilisation,
                    </div>
                    <div className="mintext">
                        de personnes se livrant à des tentatives de fraude en relation avec les moyens de paiement ou le fonctionnement même de Dropora.
                    </div>
                    <div className="mintext">
                        <strong>6.3 Collections & Cartes</strong>
                    </div>
                    <div className="mintext">
                        À un rythme variable, Dropora lancera de nouvelles collections de Cartes à collectionner, auxquelles seront attachées des Drops à gagner.
                    </div>
                    <div className="mintext">
                        Les collections sont constituées de Cartes dites :
                        <br />
                        - communes<br />
                        - rares<br />
                        - légendaires<br />
                        - mythiques<br />
                        - uniques<br />
                    </div>
                    <div className="mintext">
                        Les Cartes communes représentent environ 50% du nombre de Cartes de chaque Collection.
                    </div>
                    <div className="mintext">
                        Les Cartes mythiques sont très rares et correspondent souvent au nombre de Drops mis en jeu. Les Cartes uniques n’existent que lorsque le Drop en jeu est lui-même unique.
                    </div>
                    <div className="mintext">
                        Une Collection est annoncée plusieurs jours avant son lancement. Le lancement est donc programmé à un jour et horaire précis, susceptible d’être modifié en cas de besoin.
                    </div>
                    <div className="mintext">
                        Une collection a également une date de fin, à partir de laquelle il n’est plus possible de recevoir aléatoirement de Cartes de cette Collection. Théoriquement, l’ensemble des Cartes de la Collection aura été distribué à cette date.
                        <br />
                        A la fin de la Collection, les Cartes demeurent échangeables sur la Place de marché.
                    </div>
                    <div className="mintext">
                        Les Cartes peuvent être offertes à d’autres Utilisateurs, à condition de connaitre le Username du destinataire.
                    </div>
                    <div className="mintext">
                        Le destinataire a le choix d’accepter ou de refuser cet envoi.
                    </div>
                    <div className="mintext">
                        <strong>6.4 Pack de Cartes</strong>
                    </div>
                    <div className="mintext">
                        Lorsque l’Utilisateur dispose de suffisamment de Coins, il peut choisir d’ouvrir un Pack de Carte, dits commun, rare ou légendaire.
                    </div>
                    <div className="mintext">
                        - Un Pack commun contient 3 Cartes strictement aléatoires. L’ouvrir coûtera 10 Coins et rapportera 3 XP à l’Utilisateur
                    </div>
                    <div className="mintext">
                        - Un Pack rare contient 7 Cartes strictement aléatoires, dont 2 aléatoirement choisies parmi les Cartes rares. L’ouvrir coûtera 30 Coins et rapportera 7 XP à l’Utilisateur
                    </div>
                    <div className="mintext">
                        - Un Pack légendaire contient 9 Cartes strictement aléatoires, dont. L’ouvrir coûtera 50 Coins et rapportera 9 XP à l’Utilisateur
                    </div>
                    <div className="mintext">
                        Dropora se réserve le droit de créer d’autres packs et la répartition décrite a vocation à être modifiée selon la typologie des collections distribuées.
                    </div>
                    <div className="mintext">
                        Les packs de Cartes sont constitués de Cartes tirées aléatoirement.
                        <br />
                        Dans les jours précédant la fin d’une collection, l’algorithme Dropora inclura les Cartes résiduelles de ladite collection afin de s’assurer qu’elles aient toutes été distribuées.
                        <br />
                        Alternativement, Dropora mettra en place des bonus de jeu spécifiques pour faire gagner ces Cartes.
                    </div>
                    <div className="mintext">
                        <strong>6.5 Place de Marché</strong>
                    </div>
                    <div className="mintext">
                        L’Utilisateur a la possibilité d’acheter ou de vendre immédiatement une Carte si une offre de vente ou d’achat a déjà été publiée.
                    </div>
                    <div className="mintext">
                        L’Utilisateur peut également formuler une offre sur la Marketplace en indiquant le nombre de coins qu’il serait prêt à offrir ou se faire payer pour une Carte donnée.
                        <br />
                        La Marketplace affichera la meilleure offre à l’ensemble des Utilisateurs. Si plusieurs offres existent au même nombre de coins, la plus ancienne sera exécutée en premier.
                    </div>
                    <div className="mintext">
                        Les prix sur la marketplace sont exprimés en Coins, et les transactions sont irrévocables.
                    </div>
                    <div className="mintext">
                        La Place de Marché est également le lieu d’échange et de ventes de drops préalablement gagnés ou achetés sur la plateforme Dropora.
                    </div>
                    <div className="mintext">
                        <strong>6.6 Drop</strong>
                    </div>
                    <div className="mintext">
                        Lorsqu’un Utilisateur complète une Collection ou est éligible si tous les drops n’ont pas été affectés par manque de complétion des Utilisateurs, il devient éligible à recevoir le Drop. Cependant, il est toujours libre de revendre une ou plusieurs Cartes sur la marketplace s’il le souhaite, ou même d’envoyer une de ses Cartes à un autre Utilisateur qu’il voudrait favoriser.
                        <br />
                        A la date prévue de la fin de la collection, les Cartes ne sont plus distribuées et une période d’une durée variable est laissée pour que chacun puisse finaliser ses dernières transactions.
                        <br />
                        A l’issue de cette période, débute la période dite de « Claim » pendant laquelle l’Utilisateur actera dans l’Application son souhait de recevoir son gain sous-jacent ce qui mécaniquement aura pour effet de supprimer le drop. Le Claim ne peut être déclenché que durant la période de validité du drop préalablement définie.
                        <br />
                        Le bien est transmis par voie électronique s’il s’agit d’un bien dématérialisé ou par voie postale s’il d’agit d’un bien physique.
                        <br />
                        Les biens physiques ne sont envoyés qu’en France Métropolitaine.
                    </div>
                    <div className="mintext">
                        <strong>6.7 Coins</strong>
                    </div>
                    <div className="mintext">
                        Les coins sont principalement distribués toutes les 4 heures si l’Utilisateur se connecte à l’Application et actionne le curseur de la rubrique « Coins ». A cette occasion, ce dernier se voit attribué un nombre aléatoire de 2 à 10 coins.
                        <br />
                        Si l’Utilisateur ne réalise pas cette action, il ne pourra prétendre à aucun gain de Coins.
                        <br />
                        A date, les coins ne sont pas transférables à d’autres Utilisateurs.
                    </div>
                    <div className="mintext">
                        Les coins peuvent également être acquis par des actions à mener dans l’Application. Le tableau suivant référence les actions à réaliser et le nombre de coins attribués :
                    </div>
                    <div className="mintext">
                        <table style={{ border: "1px solid white" }}>
                            <tr>
                                <td>
                                    Tirage d’un pack commun
                                </td>
                                <td>
                                    <strong>10</strong>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Tirage d’un pack rare
                                </td>
                                <td>
                                    <strong>30</strong>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Tirage d’un pack légendaire
                                </td>
                                <td>
                                    <strong>50</strong>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Discard d’une Carte commune
                                </td>
                                <td>
                                    <strong>1</strong>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Discard d’une Carte rare
                                </td>
                                <td>
                                    <strong>2</strong>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Discard d’une Carte légendaire
                                </td>
                                <td>
                                    <strong>3</strong>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Discard d’une Carte mythique
                                </td>
                                <td>
                                    <strong>20</strong>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Discard d’une Carte unique
                                </td>
                                <td>
                                    <strong>50</strong>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Invitation d’une nouvel Utilisateur via lien de parrainage
                                </td>
                                <td>
                                    <strong>10</strong>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Inscription au Telegram de Dropora
                                </td>
                                <td>
                                    <strong>50</strong>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Gain de Trophées d’Experience
                                </td>
                                <td>
                                    <strong>Variable selon le niveau d’XP</strong>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Produit d’une vente
                                </td>
                                <td>
                                    <strong>Variable</strong>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div className="mintext">
                        <strong>6.8 Expérience - XP</strong>
                    </div>
                    <div className="mintext">
                        L’Experience est une gratification offerte aux Utilisateurs en fonction de leur implication dans l’Application.
                    </div>
                    <div className="mintext">
                        L’XP peut également être acquise par des actions à mener dans l’Application. Le tableau suivant référence les actions à réaliser et le nombre de coins attribués :
                    </div>
                    <div className="mintext">
                        <table style={{ border: "1px solid white" }}>
                            <tr>
                                <td>
                                    Tirage d’un pack commun
                                </td>
                                <td>
                                    <strong>+3XP</strong>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Tirage d’un pack rare
                                </td>
                                <td>
                                    <strong>+7XP</strong>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Tirage d’un pack légendaire
                                </td>
                                <td>
                                    <strong>+9XP</strong>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Discard d’une Carte commune
                                </td>
                                <td>
                                    <strong>-</strong>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Discard d’une Carte rare
                                </td>
                                <td>
                                    <strong>-</strong>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Discard d’une Carte légendaire
                                </td>
                                <td>
                                    <strong>-</strong>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Discard d’une Carte mythique
                                </td>
                                <td>
                                    <strong>-</strong>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Discard d’une Carte unique
                                </td>
                                <td>
                                    <strong>-</strong>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Vente de carte <small>(limité à une fois par jour par acheteur / vendeur)</small>
                                </td>
                                <td>
                                    <strong>+2XP</strong>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Donner une carte <small>(limité à une fois par jour par contact)</small>
                                </td>
                                <td>
                                    <strong>+2XP</strong>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div className="mintext">
                        <strong>6.9 Trophées</strong>
                    </div>
                    <div className="mintext">
                        Les Trophées sont des paliers que les Utilisateurs atteignent lorsqu’ils accumulent des points d’XP au gré de leur activité sur l’Application.
                    </div>
                    <div className="mintext">
                        A chaque palier atteint, l’Utilisateur débloque une récompense, qui peut prendre différentes formes :
                        <br />
                        Bonus de coins<br />
                        Pack de Carte offert<br />
                        Multiplicateur de gains de coins<br />
                        etc…
                    </div>
                    <div className="mintext">
                        <strong>6.10 Suivi des autres Utilisateurs</strong>
                    </div>
                    <div className="mintext">
                        Les Utilisateurs peuvent suivre les avancées de leurs amis s’ils connaissent leur pseudonyme.
                        <br />
                        Ils n’accèdent cependant à ces informations que lorsque l’Utilisateur a rendu son profil public.
                    </div>
                    <div className="text">
                        7. TARIFS
                    </div>
                    <div className="mintext">
                        Le service Dropora est fourni gratuitement aux Utilisateurs.
                    </div>
                    <div className="mintext">
                        Toutefois, Dropora se réserve le droit de décider d’un changement de tarif ultérieurement. Ce changement de tarif sera préalablement communiqué aux Utilisateurs par message électronique par notification « push » ou par message texte envoyé à l’adresse renseignée (au numéro de téléphone renseigné) lors de la création du Compte Utilisateur et chaque Utilisateur aura alors la liberté de souscrire ou non à un abonnement.
                    </div>
                    <div className="mintext">
                        Dropora ne prend aucun engagement quant à la pérennité de son service gratuit.
                    </div>
                    <div className="mintext">
                        L’Utilisateur demeure seul responsable des coûts d’abonnement auprès de l’opérateur de téléphonie mobile, des coûts de connexion et d’accès au réseau Internet.
                    </div>
                    <div className="text">
                        8. DROITS D’UTILISATION DES NFT
                    </div>
                    <div className="mintext">
                        Les Cartes constitutives d’une collection, ainsi que les Drops, sont des NFT inscrits dans une blockchain. A la date de cette cession des CGU, les NFT ne sont pas libres de circuler ou d’être retirés par l’Utilisateurs. Ils demeurent sur le réseau fermé de Dropora. Une version ultérieure des CGU viendra annoncer et préciser les conditions de l’ouverture de leur circulation.
                    </div>
                    <div className="mintext">
                        La détention d'un NFT par l’Utilisateur n'entraîne au profit de ce dernier aucun transfert ou cession de quelconques droits de propriété intellectuelle sur l’Œuvre numérique attachée au NFT et notamment de droits d'auteur en matière de marques dessins et modèles ou encore brevets. L’Editeur reste unique propriétaire de l'Œuvre Numérique attachée au NFT.
                    </div>
                    <div className="mintext">
                        L’Editeur concède à l'Utilisateur un droit non exclusif d'utilisation de l'Œuvre Numérique attaché au NFT, qu'il pourra utiliser uniquement dans le cadre d'une utilisation non commerciale et aux fins de divertissement. Ce droit non exclusif d'utilisation de l'ŒuvreNumérique est consenti pour toute la durée de protection des droits de propriété intellectuelle et pour le monde entier.
                    </div>
                    <div className="mintext">
                        Les droits d'utilisation consentis par l’Editeur à l’Utilisateur sont limités aux droits suivants
                        <br />
                        - le droit d'utiliser et afficher l'Œuvre Numérique sur tout support physique ou numérique, existant ou futur, dans un cadre strictement privé, pour une utilisation personnelle et non commerciale ;
                        <br />
                        - le droit de représenter l'Œuvre Numérique en version dégradée, dans la qualité d'image associée au NFT dans un cadre public à de strictes fins de cession du NFT, sur tous supports numériques, notamment les réseaux sociaux, les Sites internet personnels de l’Utilisateur.
                        <br />
                        - Il est strictement interdit d'utiliser tout NFT en tant que support d'activités promotionnelles et/ou commerciales.
                    </div>
                    <div className="mintext">
                        Si à tout moment, l’Utilisateur détruit un NFT pour quelque raison que ce soit le droit d'utilisation de l'Œuvre Numérique expirera immédiatement et de plein droit.
                    </div>
                    <div className="mintext">
                        L'Utilisateur doit connecter son Wallet via un service tiers pour réaliser un achat direct ou aux enchères, et pour récupérer ses NFT s’il remporte l’enchère. L’Editeur n'a aucun contrôle sur ces services tiers. En conséquence, l'Utilisateur reconnaît et accepte que ces services sont accessibles à ses propres risques.
                    </div>
                    <div className="mintext">
                        L'Utilisateur n'a aucune garantie de pouvoir mettre en vente les NFT sur une plateforme ou marché secondaire et l'Utilisateur n'a ainsi aucune garantie de pouvoir revendre les NFT.
                    </div>
                    <div className="mintext">
                        Les NFT peuvent faire l'objet d'importantes fluctuations de prix à la hausse comme à la baisse. L'amplitude de telles fluctuations est difficile à prévoir et ne saurait être garantie par l’Editeur. Les NFT peuvent perdre tout ou partie de leur valeur en raison de l'évolution du prix du marché, ce que l'Utilisateur reconnaît expressément. Par ailleurs, l'Utilisateur reconnaît que les opérations d'achat de détention et de revente de NFT sont susceptibles d'être soumises à des obligations légales et réglementaires, et l'Utilisateur est responsable du respect de toute obligation qui lui incombe à cet égard, notamment en termes de déclaration fiscale de conformité ou de paiement.
                    </div>
                    <div className="text">
                        9. PROPRIETE INTELLECTUELLE
                    </div>
                    <div className="mintext">
                        L’Application, le Site, les logos, textes, offres, graphismes et tout logiciel mis à disposition par Dropora (ci-après les “Droits de propriété intellectuelle”) appartiennent exclusivement à Dropora et sont protégés au titre du Droit de la propriété intellectuelle. Dropora concède aux Utilisateurs, à titre non exclusif, le droit d’utiliser les Droits de propriété intellectuelle dans la limite nécessaire à l’utilisation de l’Application et la fourniture du service Dropora. Les Utilisateurs s’interdisent notamment de copier, modifier, intégrer les Droits de propriété intellectuelle sur quelque support que ce soit, d’effectuer de l’ingénierie inverse ou d’user de toute autre méthode pour tenter d’accéder aux codes source et/ou aux protocoles des Droits de propriété intellectuelle. L’Utilisateur s’interdit de vendre, céder, donner en licence, sous-licencier, donner en garantie, transmettre de toute autre manière les Droits de propriété intellectuelle.
                    </div>
                    <div className="mintext">
                        Le non-respect de cette interdiction constituerait un acte de contrefaçon pouvant engager la responsabilité civile et/ou pénale de son auteur. Dropora se réserve le droit d’engager des poursuites judiciaires à l’encontre de tout Utilisateur qui n’aurait pas respecté cette interdiction.
                    </div>
                    <div className="text">
                        10. COOKIES
                    </div>
                    <div className="mintext">
                        La navigation sur l’Application et le Site Dropora est susceptible de provoquer l’installation de Cookies sur l’ordinateur, le smartphone ou la tablette de l’Utilisateur, mais ce dernier peut toutefois configurer son matériel pour refuser leur installation.
                    </div>
                    <div className="mintext">
                        Toutes les informations collectées indirectement ne seront utilisées que pour suivre le volume, le type et la configuration du trafic sur le Site, pour en développer la conception et l'agencement et à d'autres fins administratives et de planification et plus généralement pour améliorer le service offert.
                    </div>
                    <div className="mintext">
                        Le refus d’installation d’un Cookie peut entraîner l’impossibilité d’accéder à certains services.
                    </div>
                    <div className="mintext">
                        <strong>Publicité sur Dropora</strong>
                    </div>
                    <div className="mintext">
                        Dropora se réserve le droit d’inclure de la publicité additionnelle dans l’Application et sur le Site. Il peut s’agir de tout format de publicité utilisé sur Internet et sur mobile, tel que (mais non limité à) bannières, publicité texte et mots clés, interstitiels, films, animations, etc.
                    </div>
                    <div className="mintext">
                        <strong>Avertissement concernant les risques d’épilepsie et les précautions à prendre lors de l’utilisation d’un Jeu Vidéo (Décret n°96 – 360 du 23 avril 1996 relatif aux mises en garde concernant le Jeu Vidéo) :</strong>
                    </div>
                    <div className="mintext">
                        Certaines personnes sont susceptibles de faire des crises d'épilepsie ou d'avoir des pertes de conscience à la vue de certains types de lumières clignotantes ou d'éléments fréquents dans notre environnement quotidien. Ces personnes s'exposent à des crises lorsqu'elles jouent à certains Jeux Vidéo. Ces phénomènes peuvent apparaître alors même que le sujet n'a pas d'antécédent médical ou n'a jamais été confronté à une crise d'épilepsie. Si vous avez déjà présenté des symptômes liés à l'épilepsie (crise ou perte de conscience) en présence de stimulation lumineuse, veuillez consulter votre médecin avant toute utilisation. En tout état de cause, veuillez respecter les règles suivantes lors de l'utilisation d'un Jeu Vidéo :
                    </div>
                    <div className="mintext">
                        - évitez de jouer si vous êtes fatigué ou si vous manquez de sommeil;
                    </div>
                    <div className="mintext">
                        - assurez-vous que vous jouez dans une pièce bien éclairée en modérant la luminosité de votre écran;
                    </div>
                    <div className="mintext">
                        - jouez à bonne distance de l'écran et aussi loin que le permet le cordon de raccordement ;
                    </div>
                    <div className="mintext">
                        - en cours d'utilisation, faites des pauses de dix à quinze minutes toutes les heures.
                    </div>
                    <div className="mintext">
                        <strong>Contrôle Parental</strong>
                    </div>
                    <div className="mintext">
                        Nous encourageons les parents à s’intéresser activement à l’utilisation de l’Internet par leurs enfants. Dropora n'est pas conçu pour collecter des informations personnelles sur les moins de 13 ans, et nous n'avons pas l'intention de collecter ces informations, ni en connaissance de cause. Si vous avez moins de 13 ans, vous n'êtes pas autorisé à utiliser l’Application et vous ne devez pas fournir d'informations personnelles sur Dropora.
                    </div>
                    <div className="text">
                        11.GESTION DES DONNEES PERSONNELLES
                    </div>
                    <div className="mintext">
                        <strong>11.1 Collecte des Données Personnelles</strong>
                    </div>
                    <div className="mintext">
                        Les données à caractère personnel qui sont collectées par Dropora lors de la création du compte de l'Utilisateur sont les suivantes :  <br />
                        Pseudonyme <br />
                        Numéro de téléphone <br />
                        Age <br />
                        Genre
                    </div>
                    <div className="mintext">
                        De façon facultative, l’Utilisateur peut fournir son nom et prénom ainsi que son email.
                    </div>
                    <div className="mintext">
                        De façon nécessaire, l’Utilisateur qui souhaite réclamer son gain est tenu de fournir son adresse postale et/ou email.
                    </div>
                    <div className="mintext">
                        L'Utilisateur fournit ces informations en toute connaissance de cause.
                    </div>
                    <div className="mintext">
                        Lors de la connexion de l'Utilisateur au Site, peuvent êtres recueillis :
                        <br />
                        l'URL des liens par l'intermédiaire desquels l'Utilisateur a accédé au Site Dropora.com
                        <br />
                        Le fournisseur d'accès de l’Utilisateur.
                    </div>
                    <div className="mintext">
                        <strong>11.2 Utilisation des données personnelles</strong>
                    </div>
                    <div className="mintext">
                        Les Données Personnelles collectées auprès des Utilisateurs ont pour objectif la mise à disposition des services des Plateformes de l'Editeur et leur amélioration.
                    </div>
                    <div className="mintext">
                        Plus précisément, les utilisations sont les suivantes : <br />
                        -  Utilisation de l'Application Dropora par l'Utilisateur ; <br />
                        -  Gestion du fonctionnement et optimisation de l'Application; <br />
                        -  Vérification, identification et authentification des données transmises par l'Utilisateur ; <br />
                        -  Information de l'Utilisateur de la possibilité de communiquer avec d'autres Utilisateurs; <br />
                        -  Mise en œuvre d'une assistance Utilisateurs ; <br />
                        -  Gestion des éventuels litiges avec les Utilisateurs ; <br />
                        -  Envoi d’une newsletter ;<br />
                        -  Envoi de Drops
                    </div>
                    <div className="mintext">
                        <strong>11.3 Partage des Données Personnelles avec des tiers</strong>
                    </div>
                    <div className="mintext">
                        Les Données Personnelles peuvent être partagées avec des tiers, dans les cas suivants :
                    </div>
                    <div className="mintext">
                        -  Si la loi l'exige, l'Editeur peut effectuer la transmission de données pour donner suite aux réclamations présentées contre L'Editeur ou l’un de ses partenaires et se conformer aux procédures administratives et judiciaires ;
                    </div>
                    <div className="mintext">
                        -  Si l'Editeur est impliquée dans une opération de fusion, acquisition, cession d'actifs ou procédure de redressement judiciaire, il pourra être amenée à céder ou partager tout ou partie de ses actifs, y compris les données à caractère personnel. Dans ce cas, les Utilisateurs seraient informés.
                    </div>
                    <div className="mintext">
                        <strong>11.4 Droit des Utilisateurs</strong>
                    </div>
                    <div className="mintext">
                        En Application de la réglementation applicable aux données à caractère personnel, les Utilisateurs disposent des droits suivants :
                    </div>
                    <div className="mintext">
                        -  Ils peuvent mettre à jour les données qui les concernent en se connectant à leur compte et en configurant les paramètres de ce compte.
                    </div>
                    <div className="mintext">
                        -  Si les données à caractère personnel détenues par l'Editeur sont inexactes, ils peuvent demander la mise à jour des informations ou la résiliation de leur compte, en écrivant à : <a href="mailTo:dpo@Dropora.com">dpo@Dropora.com</a> ;
                        <br />
                        Le compte sera définitivement supprimé dans un délais de un mois après résiliation.
                    </div>
                    <div className="mintext">
                        <strong>11.5 Sécurité et confidentialité des données</strong>
                    </div>
                    <div className="mintext">
                        Nous prenons toutes les mesures raisonnables pour assurer la sécurité et la confidentialité des données à caractère personnel collectées et traitées par Dropora. Nous nous engageons à protéger ces données contre toute perte, accès non autorisé, divulgation, altération ou destruction.
                    </div>
                    <div className="mintext">
                        Nous utilisons des technologies et des procédures de sécurité adaptées pour protéger les données à caractère personnel contre les menaces potentielles. Nous sommes également en conformité avec les réglementations en vigueur en matière de protection des données à caractère personnel.
                    </div>
                    <div className="text">
                        12. LIMITATIONS DE RESPONSABILITE
                    </div>
                    <div className="mintext">
                        La responsabilité de Dropora ne peut être engagée en cas de défaillance, panne, difficulté ou interruption de fonctionnement empêchant l'accès à l'Application ou à une de ses fonctionnalités.
                    </div>
                    <div className="mintext">
                        Le matériel de connexion utilisé par l’Utilisateur est sous son entière responsabilité, de sorte qu’il doit prendre toutes les mesures appropriées pour le protéger et protéger ses propres données.
                        <br />
                        Dropora n'est pas responsable des dommages directs et indirects causés à l’Utilisateur, à des tiers et/ou à son équipement du fait de sa connexion ou de son utilisation de l’Application, de sorte que l’Utilisateur renonce à toute action contre l’Editeur de ce fait.
                    </div>
                    <div className="mintext">
                        Si l’Editeur venait à faire l'objet d'une procédure amiable ou judiciaire à raison de l’utilisation du service Dropora par un Utilisateur, il serait libre de se retourner contre ce dernier pour obtenir indemnisation de tous les préjudices, sommes, condamnations et frais qui pourraient découler de cette procédure.
                    </div>
                    <div className="text">
                        13. DROIT APPLICABLE ET JURIDICTION COMPÉTENTE
                    </div>
                    <div className="mintext">
                        Les règles en matière de droit, applicables aux contenus et aux transmissions de données sur et autour du Site, sont déterminées par la loi française. En cas de litige, n'ayant pu faire l'objet d'un accord à l'amiable, seuls les tribunaux français du ressort de la Cour d'Appel de Paris sont compétents.
                    </div>
                </div>
            </div>
            <Footer color={"white"}/>
        </>
    );
}

export default CGU;
